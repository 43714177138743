// Color system
$primary: #3326b2;
$secondary: #dddfe7;

// Spacing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  8: rem(8),
  16: rem(16),
  24: rem(24),
  32: rem(32),
);

// Body
$body-color: #575d6f;
$body-bg: #f7f7fa;


// Grid columns
$grid-gutter-width: 2rem;

// Components
$border-color: #f2f4fa;
$border-radius: rem(8);
$border-radius-sm: rem(8);

// Typography
$font-sizes: (
  11: rem(11),
  14: rem(14),
  20: rem(20),
  28: rem(28),
  32: rem(32),
);
$text-muted: #bec0cb;

// Tables
$table-cell-padding-y: rem(12);
$table-cell-padding-x: rem(16);

// Forms
$input-bg: transparent;