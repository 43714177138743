
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        



























































































ul {
  list-style: none;
  line-height: 1;
}

li {
  > a,
  > span {
    padding: rem(8) rem(12);
    border-radius: $border-radius;
  }

  > a {
    text-decoration: none;
    color: inherit;

    &.d-inline-flex {
      padding-left: rem(8);
      padding-right: rem(8);
    }

    &:hover,
    &:focus {
      background: tint-color($primary, 90%);
    }
  }

  .current {
    color: $white;
    background: $primary;
  }
}
